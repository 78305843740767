import React from 'react';
import { Routes, Route, Outlet, Link } from 'react-router-dom';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import AuthorizedRoute from './components/AuthorizedRoute';

function App() {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <AuthorizedRoute>
            <Route index element={<HomePage />} />
          </AuthorizedRoute>
        }
      />
    </Routes>
  );
}

export default App;
