import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    appName: 'CB',
  },
  reducers: {
    changeName: (state) => {
      state.appName = "Conqueror's Blade";
    },
  },
})

const { reducer } = appSlice;

export default reducer;
