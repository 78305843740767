import { createSlice } from '@reduxjs/toolkit';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    data: {},
  },
  reducers: {
    request: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    success: (state, action) => {
      const { payload: { user: { email, displayName, phoneNumber } } } = action;
      return {
        ...state,
        data: {
          email,
          displayName,
          phoneNumber
        },
        status: 'success',
      };
    },
    failure: (state) => {
      return {
        ...state,
        status: 'failure',
      };
    },
    fulfill: (state) => {
      return {
        ...state,
        loading: false,
        status: 'completed',
      };
    },
  },
});

const { actions, reducer } = authSlice;

const { request, success, failure, fulfill } = actions;

export const logUserIn = ({ email, password }) => async (dispatch) => {
  try {
    dispatch(request());
    const auth = getAuth();
    const result = await signInWithEmailAndPassword(auth, email, password);
    dispatch(success(result));
  } catch(error) {
    console.error(error);
    dispatch(failure());
  } finally {
    dispatch(fulfill());
  }
}

export default reducer;
