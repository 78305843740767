import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty }  from 'lodash';
import { Navigate, useLocation } from 'react-router-dom';

export default function AuthorizedRoute({ children }) {
  const { auth } = useSelector((state) => state);
  const location = useLocation();

  if (isEmpty(auth.data)) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children;
}
