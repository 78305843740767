import React from 'react';
import { Layout, Row, Col, Form, Button, Input } from 'antd';

const { Content } = Layout;

export default function LoginPage() {
  return (
    <Layout>
      <Content>
        <Row justify="center">
          <Col span={8}>
            <Form
              initialValues={{
                username: '',
                password: '',
              }}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true }
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">Login</Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
