import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logUserIn } from '../slices/authSlice';

export default function HomePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logUserIn({ email: 'sertac@onay.me', password: '123456' }));
  }, [dispatch]);
  return (
    <div>SA</div>
  );
}
